import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { PropTypes } from 'prop-types';
import clsx from 'clsx';

import { StoreUtil } from 'doctivity-shared/utils';

import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Chip,
  Icon,
  InputAdornment,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';

import { LoadingView, FixedHeader } from 'components';

import {
  API_TAXONOMY_LIST,
  queryTaxonomy,
} from 'store/actions/taxonomyActions';

const styles = (theme) => ({
  dialogContent: {
    overflowY: 'scroll',
    overflow: '-moz-scrollbars-vertical',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  container: {
    minHeight: '90vh',
    maxHeight: '90vh',
  },
  header: {
    position: 'sticky',
    backgroundColor: '#FAFAFA',
    top: 0,
    zIndex: 10,
  },
  selectedTaxonomies: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    '& > *': {
      marginRight: theme.spacing(),
      marginBottom: theme.spacing(),
    },
    maxHeight: '20rem',
    overflowY: 'auto',
  },
  filterInputField: theme.typography.caption,
  filterInputField2: {
    lineHeight: 'inherit',
    marginLeft: theme.spacing(),
  },
  filterIcon: {
    fontSize: 14,
    color: '#cccccc',
  },
  filterRow: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end'
  },
  searchBar: {
    width: '50%',
  },
  tree: {
    minWidth: 600,
    paddingRight: theme.spacing(3),
  },
  selectedTaxonomyItem: {
    maxWidth: 240,
  },
  noResults: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  clearAllButton: {
    fontSize: '12px',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    color: '#214E56',
    textTransform: 'none',
    fontWeight: '400',
    textDecoration: 'underline',
    padding: '3px 5px 0 5px',
    minWidth: 0,
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  }
});

class SpecialtiesTreeDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentFilter: '',
      expandedTaxonomies: [],
      selectedTaxonomies: [],
      matchedTaxonomies: null,
      hasChanged: false,
    };
  }

  componentDidMount() {
    const { taxonomy } = this.props;

    if (StoreUtil.needsLoadLongCache(taxonomy)) {
      this.fetchData();
    } else if (StoreUtil.isLoaded(taxonomy) && this.props.values?.length > 0) {
      this.onSave(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (StoreUtil.didLoadSinceLastUpdate(
        this.props.taxonomy,
        prevProps.taxonomy
      ) || this.props.taxonomy !== prevProps.taxonomy ||
      this.props.values !== prevProps.values) &&
      this.props.values?.length > 0
    ) {
      this.onSave(false);
    }
  }

  fetchData() {
    const { dispatch } = this.props;

    dispatch(
      queryTaxonomy(
        {
          where: { show: true },
          order: [
            ['grouping', 'ASC'],
            ['classification', 'ASC'],
            ['specialization', 'ASC'],
          ],
        }, 
        API_TAXONOMY_LIST,
      )
    );
  }

  render() {
    const { open, classes, taxonomy, groupings } =
      this.props;
    const {
      selectedTaxonomies,
      expandedTaxonomies,
      matchedTaxonomies,
    } = this.state;

    const taxonomyData = StoreUtil.getData(taxonomy);
    const noResults = taxonomyData && matchedTaxonomies?.length === 0;

    return (
      <Dialog
        onClose={this.onClose}
        aria-labelledby='simple-dialog-title'
        fullWidth
        maxWidth='md'
        open={open}
        scroll='paper'
      >
        <DialogTitle>Select Specialties</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.container}>
            {!taxonomyData ? (
              <LoadingView />
            ) : (
              <div>
                <FixedHeader top={0}>
                  <div className={classes.selectedTaxonomies}>
                    {selectedTaxonomies.map((taxonomyItem) => (
                      <Chip
                        label={taxonomyItem.label}
                        onDelete={() => this.onItemSelectionToggle(null, taxonomyItem.id, false)}
                        variant='outlined'
                        key={taxonomyItem.id}
                        className={classes.selectedTaxonomyItem}
                      />
                    ))}
                  </div>
                  <div className={classes.filterRow}>
                    <TextField
                      InputProps={{
                        className: clsx(
                          classes.filterInputField,
                          classes.filterInputField2
                        ),
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Icon className={classes.filterIcon}>search</Icon>
                          </InputAdornment>
                        ),
                      }}
                      className={classes.searchBar}
                      onChange={this.onSearch}
                      variant='standard'
                      margin='none'
                      type='search'
                      autoComplete='disabled'
                    />
                    <div style={{ bottom: '-4px', position: 'relative' }}>
                      {selectedTaxonomies?.length > 0 && <span className={classes.clearAllButton} onClick={this.clearAll}>Clear All Specialties</span>}
                    </div>
                  </div>
                  <Divider />
                </FixedHeader>
                {noResults ? (
                  <Typography
                    color='primary'
                    alignSelf='center'
                    className={classes.noResults}
                  >
                    No results for that filter
                  </Typography>
                ) : (
                  <RichTreeView
                    aria-label='rich object'
                    //defaultCollapseIcon={<ExpandMoreIcon color='primary' />}
                    // defaultExpandedItems={['root']}
                    //defaultExpandIcon={<ChevronRightIcon color='primary' />}
                    selectedItems={selectedTaxonomies.map((taxonomyItem) => taxonomyItem.id)}
                    expandedItems={expandedTaxonomies.map((taxonomyItem) => taxonomyItem.id)}
                    className={classes.tree}
                    items={matchedTaxonomies || groupings}
                    onItemExpansionToggle={this.onItemExpansionToggle}
                    // onExpandedItemsChange={this.onExpandedItemsChange}
                    onItemSelectionToggle={this.onItemSelectionToggle}
                    // onSelectedItemsChange={this.onSelectedItemsChange}
                    multiSelect
                    checkboxSelection                   
                  />
                )}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={() => this.onSave(true)} color='primary' variant='contained'>
            {selectedTaxonomies.length === 0 ? 'Select All' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  findTaxonomyById(id, grouping) {
    if (!grouping) {
      grouping = this.props.groupings;
    }
    for (let taxonomy of grouping) {
      if (taxonomy.id === id) {
        return taxonomy;
      }
      if (taxonomy.children) {
        const found = this.findTaxonomyById(id, taxonomy.children);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  onItemExpansionToggle = (event, id) => {
    console.log('onItemExpansionToggle', id);
    const expandedTaxonomies = this.state.expandedTaxonomies || [];
    const alreadySelectedIndex = expandedTaxonomies.findIndex((t) => t.id === id);
    if (alreadySelectedIndex !== -1) {
      expandedTaxonomies.splice(alreadySelectedIndex, 1);
    } else {
      const taxonomy = this.findTaxonomyById(id);
      if (taxonomy) {
        expandedTaxonomies.push(taxonomy);
      } else {
        console.log('Unable to find taxonomy with id: ', id);
      }
    }
    this.setState({ expandedTaxonomies });
  }

  onExpandedItemsChange = (event, ids) => {
    console.log('onExpandedItemsChange', ids);
  }

  areAllSelected(children, selected) {
    console.log('' + children.length + ' children');
    return children.every((child) => {
      const me = selected.some((t) => t.id === child.id)
      if (!me) {
        console.log('not selected', child.id);
        return false;
      }
      if (child.children) {
        return this.areAllSelected(child.children, selected);
      }
      return true;
    });
  }

  addAllChildren(children, selected) {
    children.forEach((child) => {
      if (!selected.some((t) => t.id === child.id)) {
        selected.push(child);
      }
      if (child.children) {
        this.addAllChildren(child.children, selected);
      }
    });
  }

  removeAllChildren(children, selected) {
    children.forEach((child) => {
      const index = selected.findIndex((t) => t.id === child.id);
      if (index !== -1) {
        selected.splice(index, 1);
      }
      if (child.children) {
        this.removeAllChildren(child.children, selected);
      }
    });
  }

  onItemSelectionToggle = (event, id, changeChildren = true) => {
    console.log('onItemSelectionToggle', id);
    const selectedTaxonomies = this.state.selectedTaxonomies || [];
    const alreadySelectedIndex = selectedTaxonomies.findIndex((t) => t.id === id);
    if (alreadySelectedIndex !== -1) {
      const taxonomy = selectedTaxonomies.splice(alreadySelectedIndex, 1)[0];
      console.log('already selected, removing: ', taxonomy);
      if (changeChildren && taxonomy.children && this.areAllSelected(taxonomy.children, selectedTaxonomies)) {
        this.removeAllChildren(taxonomy.children, selectedTaxonomies);
      }
    } else {
      const taxonomy = this.findTaxonomyById(id);
      if (taxonomy) {
        console.log('pushing new selection');
        selectedTaxonomies.push(taxonomy);
        if (changeChildren && taxonomy.children) {
          this.addAllChildren(taxonomy.children, selectedTaxonomies);
          // if its not expanded, expand it
          if (!this.state.expandedTaxonomies.some((t) => t.id === id)) {
            this.onItemExpansionToggle(event, taxonomy.id);
          }
        }
      } else {
        console.log('Unable to find taxonomy with id: ', id);
      }
    }
    this.setState({ selectedTaxonomies, hasChanged: true });
  }

  onSelectedItemsChange = (event, newIds) => {
    console.log('onSelectedItemsChange', newIds);
  }

  onSearch = (event) => {
    const { groupings } = this.props;

    const filter = event.target.value;
    const regex = new RegExp(`(${filter})`, 'i');

    const expandedTaxonomies = [];
    let matchedTaxonomies = null;

    if (filter !== '') {
      console.log('filtering: ', filter);
      matchedTaxonomies = [];
      groupings.forEach((grouping) => {
        const groupingChildren = [];
        let groupingMatch = false;
        if (regex.test(grouping.label)) {
          groupingMatch = true;
        }
        grouping.children?.forEach((classification) => {
          const classificationChildren = [];
          let classificationMatch = false;
          if (regex.test(classification.label)) {
            classificationMatch = true;
            groupingMatch = true;
          }
          classification.children?.forEach((specialization) => {
            if (regex.test(specialization.label)) {
              classificationMatch = true;
              groupingMatch = true;
              classificationChildren.push(specialization);
            } else {
              // hiddenTaxonomies.push(specialization);
            }
          });
          if (!classificationMatch) {
            // hiddenTaxonomies.push(classification);
          } else {
            groupingChildren.push({ ...classification, children: classificationChildren }); // clone the classificaiton and add the MATCHING children
            expandedTaxonomies.push(classification);
          }
        });
        if (!groupingMatch) {
          // hiddenTaxonomies.push(grouping);
        } else {
          matchedTaxonomies.push({ ...grouping, children: groupingChildren }); // clone the grouping and add the MATCHING children
          expandedTaxonomies.push(grouping);
        }
      });
    }
    this.setState({ expandedTaxonomies, matchedTaxonomies });
  };

  clearAll = () => {
    const { onClear } = this.props;

    onClear();
    this.setState({
      selectedTaxonomies: [],
      hasChanged: true,
    });
  }

  onSave = (userChanged) => {
    const { onChange, values } = this.props;
    const { selectedTaxonomies, hasChanged } = this.state;

    if (selectedTaxonomies != [] && selectedTaxonomies != values) {
      this.setState({ selectedTaxonomies: values, hasChanged: true });
    }
    if (!hasChanged) {
      onChange(values, userChanged);
    } else {
      onChange(selectedTaxonomies.map((t) => ({ id: t.id, name: t.label })), userChanged);
    }

    this.setState({ matchedTaxonomies: null });
  };

  onClose = () => {
    const { onClose, onChange, values } = this.props;

    onChange(values, true);
    this.setState({ selectedTaxonomies: values });
    this.setState({ matchedTaxonomies: null });

    //this.setState({ selectedTaxonomies: [], hiddenTaxonomies: [] });
    onClose();
  };
}

SpecialtiesTreeDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object,
  taxonomy: PropTypes.object.isRequired,
  values: PropTypes.array,
  groupings: PropTypes.array,
};

function mapStateToProps(state, props) {
  const { taxonomy } = state;
  const { values } = props;

  let taxonomyProps = StoreUtil.get(taxonomy, StoreUtil.COMMON_LIST);
  const groupings = [];
  let count = 0;

  const taxonomyData = StoreUtil.getData(taxonomyProps);
  let initialSelected = [];

  if (taxonomyData) {
    const uniqueGroupingNames = new Set();
    taxonomyData.map((taxonomyItem) => {
      if (taxonomyItem.grouping && taxonomyItem.grouping !== '') {
        console.log(taxonomyItem);
        uniqueGroupingNames.add(taxonomyItem.grouping);
      }
    });

    uniqueGroupingNames.forEach((grouping, index) => {
      groupings.push({
        id: `group-${index}`,
        label: grouping,
        children: taxonomyData
          .filter(
            (groupingItem) =>
              groupingItem.grouping === grouping &&
              (!groupingItem.specialization ||
                groupingItem.specialization === '')
          )
          .map((classificationItem) => {
            const children = taxonomyData
              .filter(
                (specializationItem) =>
                  specializationItem.grouping === grouping &&
                  specializationItem.classification ==
                    classificationItem.classification &&
                  specializationItem.specialization &&
                  specializationItem.specialization !== ''
              )
              .map((specializationItem) => {
                count += 1;
                const taxonomy = {
                  id: `${specializationItem.code}`,
                  label: specializationItem.specialization,
                };
                if (values?.includes(taxonomy.id)) {
                  initialSelected.push(taxonomy);
                }
                return taxonomy;
              });
              /*
              if (children.length > 0) {
                const taxonomy = {
                  id: `all-${classificationItem.code}`,
                  label: `Select All - ${classificationItem.classification}`,
                };
                children.unshift(taxonomy);
                if (values?.includes(taxonomy.id)) {
                  initialSelected.push(taxonomy);
                }
                count += 1;
              }
              */
            count += 1;
            const taxonomy = {
              id: `${classificationItem.code}`,
              label: classificationItem.classification,
              children,
            };
            if (values?.includes(taxonomy.id)) {
              initialSelected.push(taxonomy);
            }
            return taxonomy;
          }),
      });
      count += 1;
    });
  }

  return {
    taxonomy: taxonomyProps,
    groupings,
    values: initialSelected,
    taxonomyCount: count,
  };
}

const styled = withStyles(styles)(SpecialtiesTreeDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as SpecialtiesTreeDialog };
