import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  Typography,
  Card,
  CardContent,
  FormControl,
  TextField,
  IconButton,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { GetApp } from '@mui/icons-material';

import { DatePicker } from '@mui/x-date-pickers';

import withStyles from '@mui/styles/withStyles';

import { withRouter, ProviderNotesUtil } from 'utils';
import Chart from 'react-apexcharts';
import { axiosInstance, StringUtil, ColorUtil } from 'utils';
import {
  CsvUtil,
  StoreUtil,
  DateUtil,
  DataUtil,
  EncounterTypeUtil,
} from 'doctivity-shared/utils';
import { LoadingView } from 'components';

import { loadAnalytics } from 'store/actions/analyticsActions';
import { listActiveUsers } from 'store/actions/usersActions';
import fileDownload from 'js-file-download';
import moment from 'moment';

const styles = (theme) => ({
  filters: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacer: {
    flex: 1,
  },
  pdfButton: {
    flex: 1,
  },
  pdfIcon: {
    fontSize: 20,
    fontWeight: 200,
  },
  filterSpace: {
    width: 4,
  },
  rangeControl: {
    minWidth: 90,
  },
  userFilter: {
    minWidth: 270,
  },
  typeControl: {
    width: 180,
  },
});

const minimumStartDate = new Date(2018, 0, 1);

class ProviderCommentsTaggedPerDayChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterUser: null,
      types: ProviderNotesUtil.allCountTypes,
    };
  }

  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.activityNotesTaggedPerDay)) {
      this.fetchData();
    }

    if (
      StoreUtil.needsLoadLongCache(this.props.users) ||
      this.props.usersClientId !== this.props.app.selectedClient
    ) {
      this.fetchUsers();
    }
  }

  componentDidUpdate(prevProps) {
    // anytime the client changes
    if (this.props.app.selectedClient !== prevProps.app.selectedClient 
      || this.props.dateRange.startDate.getDate() !== prevProps.dateRange.startDate.getDate()
      || this.props.dateRange.endDate.getDate() !== prevProps.dateRange.endDate.getDate()) {
      this.fetchData();
    } else if (
      StoreUtil.needsLoadMediumCache(this.props.activityNotesTaggedPerDay)
    ) {
      this.fetchData();
    } else if (
      StoreUtil.hasSavedSinceUpdate(
        this.props.editComment,
        prevProps.editComment
      )
    ) {
      this.fetchData();
    }

    if (
      StoreUtil.needsLoadLongCache(this.props.users) ||
      this.props.usersClientId !== this.props.app.selectedClient
    ) {
      this.fetchUsers();
    }
  }

  getDateUnits() {
    const { startDate, endDate } = this.props.dateRange;

    return moment(endDate).diff(moment(startDate), 'days', true) > 31
      ? 'months'
      : 'days';
  }

  fetchData() {
    const { dispatch, clientId } = this.props;
    const { startDate, endDate } = this.props.dateRange;
    const { filterUser } = this.state;

    dispatch(
      loadAnalytics({
        types: this.state.types,
        type: 'PROVIDER_COMMENTS_TAGGED_PER_DAY',
        filter: {
          client_id: clientId,
          user_id: filterUser?.id,
          comment_date: {
            $gte: DateUtil.formatDateTimeFromDB(startDate),
            $lte: DateUtil.formatDateTimeFromDB(endDate),
          },
        },
        dateUnits: this.getDateUnits(),
      })
    );
  }

  fetchUsers() {
    const { dispatch, app } = this.props;

    dispatch(listActiveUsers(app.selectedClient));
  }

  fetchCSVDownload() {
    const { startDate, endDate } = this.props.dateRange;
    const { filterUser } = this.state;
    const { clientId } = this.props;

    axiosInstance
      .post('/Analytics', {
        type: 'PROVIDER_COMMENTS_TAGGED_PER_DAY',
        filter: {
          client_id: clientId,
          comment_date: {
            $gte: DateUtil.formatDateTimeFromDB(startDate),
            $lte: DateUtil.formatDateTimeFromDB(endDate),
          },
          user_id: filterUser?.id,
        },
        dateUnits: this.getDateUnits(),
        format: 'csv',
        headers: {
          'Content-Type': 'text/csv',
        },
      },
      {
        params: {
          selected_client_id: this.props.clientId,
        }
      })
      .then((response) => {
        const filename =
          filterUser && filterUser !== 'All Users'
            ? `${filterUser.first_name}_${
                filterUser.last_name
              }_doctivity_notes_per_day_${moment().format('YY_MM_DD')}.csv`
            : `doctivity_notes_per_day_${moment().format('YY_MM_DD')}.csv`;
        try {
          const data = this.formatCSV(response.data);
          fileDownload(data, filename);
        } catch (err) {
          console.error(`Could not format csv for ${filename}`);
          console.error(err);
          fileDownload(response.data, filename);
        }
      });
  }

  formatCSV(data) {
    let csvColumns = CsvUtil.stringToMatrix(data);
    if (csvColumns.length > 0) {
      let encounterTypeIndex = -1;
      let encounterDateIndex = -1;
      let encounterDayIndex = -1;
      for (let index = 0; index < csvColumns[0].length; index++) {
        if (csvColumns[0][index] === 'encounter_type_id') {
          csvColumns[0][index] = 'encounter_type';
          encounterTypeIndex = index;
        } else if (csvColumns[0][index] === 'encounter_date') {
          encounterDateIndex = index;
        } else if (csvColumns[0][index] === 'encounter_date_day') {
          encounterDayIndex = index;
        }
      }

      if (
        encounterTypeIndex > -1 &&
        encounterDateIndex > -1 &&
        encounterDayIndex > -1
      ) {
        for (let index = 1; index < csvColumns.length; index++) {
          if (csvColumns[index].length > 1) {
            const encounterType = parseInt(
              csvColumns[index][encounterTypeIndex],
              10
            );
            const encounterTypeName = EncounterTypeUtil.getName(encounterType);
            csvColumns[index][encounterTypeIndex] = encounterTypeName;

            const encounterDate = csvColumns[index][encounterDateIndex];
            const encounterDay = csvColumns[index][encounterDayIndex];
            const formattedDate = moment(encounterDate + encounterDay).format(
              'MM/DD/YYYY'
            );
            csvColumns[index][encounterDateIndex] = formattedDate;
          }
        }

        csvColumns = DataUtil.removeMatrixColumn(csvColumns, encounterDayIndex);
      }
    }

    return CsvUtil.matrixToString(csvColumns);
  }

  render() {
    const { classes, activityNotesTaggedPerDay, users, series, categories } = this.props;
    const { types } = this.state;

    if (!StoreUtil.isLoaded(activityNotesTaggedPerDay) || !StoreUtil.isLoaded(users)) {
      return <LoadingView />;
    }

    let options = ['All Users'];
    if (StoreUtil.isLoaded(users)) {
      options = options.concat(StoreUtil.getData(users));
    }

    return (
      <div>
        <Card>
          <CardContent>
            <div className={classes.header}>
              <Typography component='h2' variant='h6' color='primary'>
                Tagged Per Day
              </Typography>
              <div className={classes.pdfButton}>
                <IconButton
                  onClick={() => {
                    this.fetchCSVDownload();
                  }}
                  size='large'
                >
                  <GetApp className={classes.pdfIcon} />
                </IconButton>
              </div>
              <div className={classes.spacer} />
            </div>

            <div className={classes.filters}>
              <FormControl className={classes.userFilter}>
                <Autocomplete
                  options={options}
                  getOptionLabel={(u) =>
                    u !== 'All Users' ? `${u.last_name}, ${u.first_name}` : u}
                  autoComplete
                  disableClearable
                  defaultValue={'All Users'}
                  value={this.state.filterUser || 'All Users'}
                  onChange={this.onChangeUser}
                  renderInput={(params) => (
                    <TextField {...params} label='User' />
                  )}
                />
              </FormControl>
              <div className={classes.filterSpace} />
              <FormControl className={classes.typeControl}>
                <InputLabel>Type</InputLabel>
                <Select
                  value={types.length > 1 ? 'All' : types[0]}
                  onChange={this.onChangeType}
                  label='Type'
                >
                  <MenuItem value='All'>All</MenuItem>
                  {ProviderNotesUtil.allCountTypes.map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {ProviderNotesUtil.formatCountTypeForDisplay(type)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={classes.filterSpace} />
              <FormControl className={classes.rangeControl}>
                <DatePicker
                  renderInput={(params) => <TextField {...params} />}
                  label='From'
                  name='from'
                  value={this.props.dateRange.startDate}
                  minDate={minimumStartDate}
                  maxDate={this.props.dateRange.endDate}
                  onChange={this.onChangeStartDate}
                  format={DateUtil.LDML_DISPLAY_FORMAT}
                />
              </FormControl>
              <div className={classes.filterSpace} />
              <FormControl className={classes.rangeControl}>
                <DatePicker
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                  label='To'
                  value={this.props.dateRange.endDate}
                  minDate={this.props.dateRange.startDate}
                  maxDate={new Date()}
                  onChange={this.onChangeEndDate}
                  name='to'
                  format={DateUtil.LDML_DISPLAY_FORMAT}
                />
              </FormControl>
            </div>

            <Chart
              type='bar'
              height={500}
              options={{
                chart: {
                  id: 'TaggedPerDay',
                  toolbar: {
                    show: false,
                  },
                  animations: {
                    enabled: false,
                  },
                  stacked: true,
                },
                yaxis: {
                  labels: {
                    minWidth: 100,
                  },
                },
                xaxis: {
                  categories,
                  labels: {
                    hideOverlappingLabels: true,
                    rotate: -45,
                    rotateAlways: true
                  },
                  tickPlacement: 'on',
                },
                tooltip: {
                  enabled: true,
                  shared: true,
                  intersect: false,
                  y: {
                    formatter: StringUtil.numberForChart,
                  },
                },
                dataLabels: {
                  enabled: true,
                  dropShadow: {
                    enabled: true,
                    left: 0,
                    top: 0,
                    opacity: 0.4,
                  },
                  formatter: StringUtil.numberForChart,
                  offsetY: 7,
                },
                colors: ColorUtil.getColors(7),
              }}
              series={series}
            />
          </CardContent>
        </Card>
      </div>
    );
  }

  onChangeUser = (event, newUser) => {
    this.setState(
      { filterUser: newUser !== 'All Users' ? newUser : null },
      this.fetchData
    );
  };

  onChangeStartDate = (newDate) => {
    if (moment(newDate).isAfter(moment(minimumStartDate))) {
      this.props.onChangeStartDate(newDate);
    }
  };

  onChangeEndDate = (newDate) => {
    this.props.onChangeEndDate(newDate);
  };

  onChangeType = (event) => {
    const newType = event.target.value;

    this.setState(
      {
        types: newType !== 'All' ? [newType] : ProviderNotesUtil.allCountTypes,
      },
      this.fetchData
    );
  };
}

ProviderCommentsTaggedPerDayChart.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  users: PropTypes.object,
  usersClientId: PropTypes.number,
  editComment: PropTypes.object,
  activityNotesTaggedPerDay: PropTypes.object.isRequired,
  dateRange: PropTypes.object,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func,
  series: PropTypes.object,
  categories: PropTypes.object,
  types: PropTypes.object
};

function mapStateToProps(state) {
  const { app, analytics, user, users, providerComments, types } = state;

  return {
    app,
    user,
    types,
    clientId: app.selectedClient,
    users: StoreUtil.get(users, StoreUtil.COMMON_LIST),
    usersClientId: users.listClientId,
    editComment: StoreUtil.get(providerComments, StoreUtil.COMMON_EDIT_ITEM),
    activityNotesTaggedPerDay: analytics.activityNotesTaggedPerDay || {},
    series: analytics.seriesActivityNotesTagged,
    categories: analytics.categoriesTagged,
  };
}

const styled = withStyles(styles)(ProviderCommentsTaggedPerDayChart);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentsTaggedPerDayChart };
