import React, { Component, Fragment } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  TextField,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  InputAdornment,
  IconButton,
  LinearProgress,
} from '@mui/material';
import Icon from '@mui/material/Icon';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import {
  DateUtil,
  StoreUtil,
} from 'doctivity-shared/utils';
import {
  TableUtil,
  withIsMobile,
} from 'utils';
import dotProp from 'dot-prop-immutable';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {},
  tableDialog: {
    minWidth: 500,
  },
  tableHeader: {
    backgroundColor: 'rgba(0,0,0,0.03)',
  },
  headerCell: {
    padding: 2,
    fontSize: 12,
    fontWeight: 'inherit',
    verticalAlign: 'top',
    borderBottom: 0, // remove bottom row border
  },
  tableBody: {
    fontWeight: 300,
  },
  filterIconButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 28,
  },
  filterInputField: theme.typography.caption,
  filterInputField2: {
    lineHeight: 'inherit',
  },
  filterIcon: {
    fontSize: 14,
    color: '#cccccc',
  },
  dateField: {
    '& > div > input': {
      fontSize: '0.75rem',
    },
    '& > div > div > button': {
      width: 20,
      '& > span > svg': {
        fontSize: 18,
      },
    },
    '& > p': {
      display: 'none',
    },
  },
  altRow: {
    backgroundColor: 'rgba(0,0,0,0.03)',
    cursor: 'pointer',
    height: 30,
  },
  invalidRow: {
    backgroundColor: 'rgba(255,0,0,0.1)',
    cursor: 'pointer',
    height: 30,
  },
  aggregateRow: {
    backgroundColor: 'rgba(0,0,0,0.06)',
    cursor: 'pointer',
    height: 30,
    fontWeight: 800,
  },
  multiSelectedRow: {
    backgroundColor: 'rgba(0, 168, 135, 0.3)',
    cursor: 'pointer',
    height: 30,
  },
  regRow: {
    cursor: 'pointer',
    height: 30,
  },
  link: {
    fontWeight: 'inherit',
    color: 'inherit',
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
  },
  mutedCell: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  condensedCell: {
    padding: 2,
    fontSize: 12,
    fontWeight: 'inherit',
  },
  spinnerWrapper: {
    textAlign: 'center',
    padding: 120,
  },
  loadingCell: {
    height: 2,
    padding: 0,
    borderBottom: 0,
  },
  topCell: {
    height: 1,
    padding: 0,
    backgroundColor: 'rgba(0,0,0,0.03)',
  },
  loading: {
    height: 2,
    width: '100%',
  },
  noData: {
    fontWeight: 1000,
    fontSize: 18,
    textAlign: 'center',
    padding: 50,
  },
  title: {
    marginBottom: 10,
  },
  settingsRow: {
    height: 16,
    textAlign: 'right',
    borderBottom: 0,
    marginRight: '50',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  groupRow: {
    height: 28,
  },
  groupCell: {
    padding: 2,
    fontSize: 12,
    fontWeight: 800,
    textAlign: 'center',
    borderBottom: 0, // remove bottom row border
  },
  custom: {
    // this allows top level pages to override and pass in styles
  },
  headerContainer: {},
  header: {},
  leftIcon: {},
  above: {},
  between: {},
  below: {},
  none: {},
});

/* TODO: remove all of this DOS stuff */
class DynamicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false, // controls the diaolog box open status (end DOS option)
      hasApplicationAskedForEndDOS: false, // indicaates if the end DOS question has been asked
      startDOS: null, // the start DOS
      endDOS: null, // the end DOS
      hasUserRequestedEndDOS: false,
      dateRangeLabel: null,
      columnGroups: null,
      expandedRows: [],
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.onRowsPerPageChange = this.onRowsPerPageChange.bind(this);

    // opens the end DOS question dialog box
    this.openEndDOSDialogBox = this.openEndDOSDialogBox.bind(this);
    // handle the case in which the user rejects an end DOS
    this.handleEndDosReject = this.handleEndDosReject.bind(this);
    // handle the case in which the user requests an end DOS
    this.handleEndDosAccept = this.handleEndDosAccept.bind(this);
    // disables the days before the start DOS on the data picker
    this.disableDaysBeforeStartDOS = this.disableDaysBeforeStartDOS.bind(this);

    this.handleDateOnChange = this.handleDateOnChange.bind(this);
    this.handleDateOnClose = this.handleDateOnClose.bind(this);
    this.handleDateInputChange = this.handleDateInputChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onOrderChange = this.onOrderChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.handleExpandClick = this.handleExpandClick.bind(this);
  }

  componentDidMount() {
    this.cacheColumnPaths();

    this.cacheColumnGroups();
  }

  componentDidUpdate(prevProps) {
    // view as user changed
    if (this.props.columns !== prevProps.columns) {
      this.cacheColumnPaths();
      this.cacheColumnGroups();
    }
  }

  onOrderChange(newOrderBy) {
    const query = { ...this.props.query };
    let defaultOrderBy = this.props.idKey;
    if (Array.isArray(defaultOrderBy)) {
      defaultOrderBy = defaultOrderBy[0];
    }
    let oldOrderBy = defaultOrderBy;
    let order = 'DESC';

    let oldHasOrder = false;
    if (query.order && query.order.length > 0) {
      // save out old order if it hasn't been saved
      if (!query.baseOrder) {
        query.baseOrder = query.order;
      }
      oldOrderBy = query.order[0].slice(0, query.order[0].length - 1).join('.');
      order = query.order[0][query.order[0].length - 1];
      oldHasOrder = true;
    } else {
      // no base order, so it must be the id field
      query.baseOrder = [[defaultOrderBy, 'DESC']];
    }

    if (oldHasOrder && oldOrderBy === newOrderBy) {
      // already sorting this column, change direction
      if (order.toUpperCase() === 'ASC') {
        order = 'DESC';
      } else {
        order = 'ASC';
      }
      query.order[0][query.order[0].length - 1] = order;
    } else {
      query.order = [];
      if (query.baseOrder) {
        query.order = query.order.concat(query.baseOrder);
      }

      let orderItem;
      // if new order by is a deep object, with "." operators in it,
      // need to be made into array with each piece of path in array
      if (newOrderBy.indexOf('.') > -1) {
        orderItem = newOrderBy.split('.');
        orderItem.push(order.toUpperCase());
      } else {
        orderItem = [newOrderBy, order.toUpperCase()];
      }

      // make sure this isn't in the base sort
      let includedInBase = false;
      for (let x = 0; x < query.order.length; x++) {
        const sort = query.order[x];
        if (sort.length === orderItem.length) {
          let allMatch = true;
          for (let y = 0; y < sort.length - 1; y++) {
            if (sort[y] !== orderItem[y]) {
              allMatch = false;
              break;
            }
          }
          includedInBase = allMatch;
          break;
        }
      }
      if (!includedInBase) {
        query.order.unshift(orderItem);
      }
    }

    if (this.props.onQueryChange) {
      this.props.onQueryChange(query);
    }
  }

  onFilterChange(columnKey, event, committed = false) {
    const column = this.getColumnByKey(columnKey);
    let query = { ...this.props.query };

    let fields = column.filterFields;
    if (!fields) {
      fields = [columnKey];
    }

    let objPath = '';
    let parentObj = query;
    let columnFieldName = fields[0];
    if (fields[0].indexOf('.') > -1 && parentObj.include) {
      let varNames = fields[0].split('.');
      columnFieldName = varNames[varNames.length - 1];
      varNames = varNames.slice(0, -1);
      let x;
      varNames.forEach((name) => {
        for (x = 0; x < parentObj.include.length; x++) {
          if (parentObj.include[x].association === name) {
            if (objPath) {
              objPath += `.include.${x}`;
            } else {
              objPath += `include.${x}`;
            }
            parentObj = parentObj.include[x];
            break;
          }
        }
      });
    }

    if (!parentObj.where) {
      parentObj.where = {};
    }
    let filters = parentObj.where;
    if (fields.length > 1) {
      if (!filters.$or) {
        filters.$or = {};
      }
      filters = filters.$or;
    }

    fields.forEach((field) => {
      let value;

      columnFieldName = field;
      const i = field.lastIndexOf('.');
      if (i > -1) {
        columnFieldName = field.substring(i + 1);
      }

      if (column.type === 'date') {
        value = event;

        if (column.filterFormatter) {
          value = column.filterFormatter(value, committed);
        }

        let dateRange = 1;
        if (this.state.endDOS !== null) {
          // if there exists an end DOS set as range
          dateRange = Math.abs(
            moment(this.state.startDOS).diff(this.state.endDOS, 'days')
          );
        }

        if (value) {
          filters[columnFieldName] = {
            $and: {
              $gt: moment(this.state.startDOS || value)
                .subtract(1, 'd')
                .format(DateUtil.FORM_DATE_FORMAT),
              $lt: moment(this.state.startDOS || value)
                .add(dateRange, 'd')
                .format(DateUtil.FORM_DATE_FORMAT),
            },
          };
        } else {
          delete filters[columnFieldName];
        }
      } else {
        // else not date
        value = event.target.value;

        if (value && column.autoCapitalize === true) {
          value = value.toUpperCase();
        }
        if (column.filterFormatter) {
          value = column.filterFormatter(value, committed);
        }

        if (value) {
          if (
            value.length > 2 &&
            value.startsWith('"') &&
            value.endsWith('"')
          ) {
            filters[columnFieldName] = {
              $eq: value.substring(1, value.length - 1),
            };
          } else if (column.filterExact && column.filterExact === true) {
            filters[columnFieldName] = { $eq: value };
          } else if (column.fulltext && column.fulltext === true) {
            filters[columnFieldName] = { $match: value };
          } else {
            filters[columnFieldName] = { $like: `%${value}%` };
          }
        } else {
          delete filters[columnFieldName];
        }
      }
    });

    let fullPath;
    if (objPath) {
      fullPath = `${objPath}.where`;
    } else {
      fullPath = 'where';
    }
    if (fields.length > 1) {
      fullPath = `${fullPath}.$or`;
    }
    if (Object.keys(filters).length === 0) {
      query = dotProp.delete(query, fullPath);
    } else {
      query = dotProp.set(query, fullPath, filters);
    }
    // The user changes filters so we need to reset to the first page
    if (query.offset !== undefined) {
      query.offset = 0;
    }

    if (this.props.onQueryChange) {
      this.props.onQueryChange(query);
    }
  }

  onPageChange(event, page) {
    const query = { ...this.props.query };

    query.offset = page * query.limit;

    if (this.props.onQueryChange) {
      this.props.onQueryChange(query);
    }
  }

  onRowsPerPageChange(event) {
    const query = { ...this.props.query };

    query.limit = event.target.value;
    query.offset = 0;

    if (this.props.onQueryChange) {
      this.props.onQueryChange(query);
    }
  }

  onRowClick(row, e) {
    if (this.props.onRowClick) {
      this.props.onRowClick(row, e);
    }
  }

  handleExpandClick(row, e) {
    e.stopPropagation();
    if (this.props.expandableRows) {
      const { expandedRows } = this.state;

      // Is row already expanded?
      const isRowExpanded =
        expandedRows.filter((r) => r === row[this.props.idKey]).length > 0;
      if (isRowExpanded) {
        this.setState((prevState) => ({
          expandedRows: prevState.expandedRows.filter(
            (r) => r !== row[this.props.idKey]
          ),
        }));
      } else {
        this.setState((prevState) => ({
          expandedRows: [...prevState.expandedRows, row[this.props.idKey]],
        }));
      }
    }
  }

  // date  picker reference set up (used to ope the data picker programmatically)
  setDatePickerDialogReference(ref) {
    this.datePickerDialog = ref;
  }

  getColumnByKey(key) {
    return this.props.columns.find((column) => column.key === key);
  }

  flattenWhereQuery(obj, child, path) {
    if (!child) {
      return;
    }
    const where = child.where;
    if (where) {
      if (where.$or) {
        // where = where.$or; /// this is causing issues, and not sure why it exists
        // going to add each instead
        Object.keys(where.$or).forEach((prop) => {
          if (path) {
            obj[`${path}.${prop}`] = where.$or[prop];
          } else {
            obj[prop] = where.$or[prop];
          }
        });
      }

      Object.keys(where).forEach((prop) => {
        if (path) {
          obj[`${path}.${prop}`] = where[prop];
        } else {
          obj[prop] = where[prop];
        }
      });
    }

    if (child.include && Array.isArray(child.include)) {
      child.include.forEach((include) => {
        if (path) {
          this.flattenWhereQuery(
            obj,
            include,
            `${path}.${include.association}`
          );
        } else {
          this.flattenWhereQuery(obj, include, include.association);
        }
      });
    }
  }

  disableDaysBeforeStartDOS(day) {
    if (
      this.state.hasUserRequestedEndDOS &&
      this.state.hasApplicationAskedForEndDOS
    ) {
      const format = DateUtil.DISPLAY_FORMAT;
      const formatted = moment(day).format(format);
      const isBeforeStartDOS =
        moment(this.state.startDOS).diff(formatted, 'days') >= 0;
      return isBeforeStartDOS;
    }
    return false;
  }

  handleEndDosReject() {
    // if the user does not want to select a date range
    this.setState(
      {
        open: false,
        endDOS: null, // reset endDOS
      },
      () => {
        this.handleDateOnChange('DOS', this.state.startDOS);
      }
    );
  }

  handleEndDosAccept() {
    // handle case where the end DOS is requested
    // close dialog box, indicate that the end DOS has been requested, and reset endDOS
    this.setState(
      {
        open: false,
        endDOS: null,
        hasUserRequestedEndDOS: true,
      },
      () => {
        // open calendar
        this.datePickerDialog.open();
      }
    );
  }

  openEndDOSDialogBox() {
    // opens the dialog box to ask for end DOS
    this.setState({
      open: true,
      hasApplicationAskedForEndDOS: true,
    });
  }

  datePickerDialog; // date  picker reference set up (used to ope the data picker programmatically)

  handleDateOnChange(columnKey, value) {
    const onDashboard =
      window.location.href.indexOf('nursenav/dashboard') !== -1;
    if (
      !this.state.hasApplicationAskedForEndDOS &&
      value !== null &&
      onDashboard
    ) {
      // if the bend DOS question has not been asked and the Date has not been cleared
      const format = DateUtil.DISPLAY_FORMAT;
      const formatted = moment(value).format(format);
      this.setState({
        startDOS: formatted,
      });

      return this.openEndDOSDialogBox();
    }

    // reset end DOS checks
    this.setState({
      hasApplicationAskedForEndDOS: false,
      hasUserRequestedEndDOS: false,
    });

    if (value && this.state.hasUserRequestedEndDOS) {
      const format = DateUtil.DISPLAY_FORMAT;
      const formatted = moment(value).format(format);
      const addADay = moment(formatted).add(1, 'days');
      const startDOS = this.state.startDOS;
      this.setState(
        {
          endDOS: addADay,
          dateRangeLabel: `${startDOS} - ${formatted}`,
        },
        () => {
          this.onFilterChange(columnKey, this.state.startDOS);
        }
      );
    } else if (!this.state.hasApplicationAskedForEndDOS) {
      this.setState(
        {
          startDOS: null,
          endDOS: null,
          dateRangeLabel: null,
        },
        () => {
          this.onFilterChange(columnKey, value);
        }
      );
    } else {
      this.setState({
        dateRangeLabel: null,
      });
      this.onFilterChange(columnKey, this.state.startDOS);
    }

    return null;
  }

  handleDateInputChange(columnKey, event) {
    const value = event.target.value;
    if (
      value &&
      value.length === DateUtil.DISPLAY_FORMAT.length &&
      value.indexOf('_') === -1
    ) {
      const newDate = moment(value, DateUtil.DISPLAY_FORMAT);
      if (newDate) {
        this.onFilterChange(columnKey, newDate);
      }
    } else if (!value) {
      this.setState(
        {
          startDOS: null,
          endDOS: null,
          dateRangeLabel: null,
        },
        () => {
          this.onFilterChange(columnKey, null);
        }
      );
    }
  }

  handleDateOnClose() {
    if (this.state.hasUserRequestedEndDOS && !this.state.endDOS) {
      this.handleDateOnChange('DOS', null);
    }
  }

  cacheColumnGroups() {
    const { columns } = this.props;

    // loop through and cache the paths for each column
    // this avoids searching for the . in every key for everycell
    const columnGroups = [];
    let hasGroups = false;
    let lastGroup = null;
    columns.forEach((column) => {
      if (column.group && column.group !== lastGroup) {
        hasGroups = true;
        lastGroup = column.group;
        columnGroups.push({
          label: column.group,
          colspan: 1,
        });
      } else if (column.group) {
        columnGroups[columnGroups.length - 1].colspan++;
      } else {
        columnGroups.push({
          label: '',
          colspan: 1,
        });
      }
    });

    if (hasGroups) {
      this.setState({ columnGroups });
    } else if (this.state.columnGroups) {
      this.setState({ columnGroups: null });
    }
  }

  cacheColumnPaths() {
    const { columns } = this.props;

    // loop through and cache the paths for each column
    // this avoids searching for the . in every key for everycell
    columns.map((column) => {
      let usedKey = column.key;
      while (usedKey.endsWith('_')) {
        usedKey = usedKey.substring(0, usedKey.length - 1);
      }
      column.pathLocal = usedKey.split('.');
      return null;
    });
  }

  resolveColumnPath(column, row) {
    if (!column.pathLocal) {
      this.cacheColumnPaths();
    }
    return column.pathLocal.reduce(
      (prev, curr) => (prev ? prev[curr] : undefined),
      row
    );
  }

  cellString(value, column) {
    if (typeof value === 'undefined' || value == null) {
      return '';
    }
    if (column.type === 'bool') {
      return TableUtil.boolToCheckIcon(value);
    }
    return String(value);
  }

  isRowMultiSelected = (row) => {
    const { idKey, multiSelectedRows } = this.props;

    if (!multiSelectedRows) {
      return false;
    }

    if (Array.isArray(idKey)) {
      console.log('multiselect doesnt support multiple id keys!');
      return false;
    }
    return multiSelectedRows.some(
      (multiSelectRow) => multiSelectRow[idKey] === row[idKey]
    );
  };

  handleMultiSelect = (row) => {
    const { idKey, onMultiSelect, multiSelectedRows } = this.props;

    if (onMultiSelect && multiSelectedRows) {
      let newMultiSelectedRows = multiSelectedRows;
      if (this.isRowMultiSelected(row)) {
        newMultiSelectedRows = multiSelectedRows.filter(
          (multiSelectRow) => multiSelectRow[idKey] !== row[idKey]
        );
      } else {
        newMultiSelectedRows.push(row);
      }

      onMultiSelect(newMultiSelectedRows, row);
    }
  };

  onClearSearch = (query) => {

    query.where = {};
    if (query.include) {
      query.include.map((i) => {
        i.where = {};
      })
    }


    this.props.onQueryChange(query);
  }

  render() {
    const {
      classes,
      rowClasses,
      idKey,
      showFilters,
      data,
      query,
      header,
      noDataMessage,
      preloadMessage,
      inDialog,
      rowExtraRenderer,
      onMultiSelect,
      ContainerType,
      colcount,
    } = this.props;

    // remove hideForMobile
    let columns = this.props.columns;
    if (this.props.isMobile) {
      columns = columns.filter((c) => !c.hideForMobile);
    }

    const { columnGroups } = this.state;

    /* DATA CAN BE SEVERAL THINGS!!
      IT CAN BE:
        1) StoreUtil LoadedData object
        2) An array
        3) An object with .data, .count,
    */
    let dataCount = -1;
    let isDataLoading = true;
    let dataRows = null;
    let didLoadOccur = true;
    if (StoreUtil.isLoadedData(data)) {
      isDataLoading = StoreUtil.isLoading(data);
      if (StoreUtil.getData(data)) {
        const d = StoreUtil.getData(data);
        if (Array.isArray(d.rows)) {
          if (Array.isArray(d.count)) {
            dataCount = d.count.length;
          } else {
            dataCount = d.count;
          }
          dataRows = d.rows;
        } else {
          dataRows = d;
        }
      } else if (isDataLoading) {
        didLoadOccur = false;
      }
    } else if (data && Array.isArray(data)) {
      isDataLoading = false;
      dataCount = data.length;
      dataRows = data;
    } else if (data && !data.loading && data.count >= 0) {
      isDataLoading = false;
      dataCount = data.count;
      dataRows = data.rows;
      dataRows = data.rows;
    } else if (data && data.placeholder) {
      didLoadOccur = data.showNoData === true;
      dataCount = 0;
      dataRows = [];
      isDataLoading = data.showLoading === true;
    } else {
      didLoadOccur = false;
    }

    let altRowFlag = true;
    let rowClass;

    let rowsPerPage = 25;
    let page = 0;
    let pages = 0;
    let order = 'desc';
    let orderBy = this.props.idKey;
    if (Array.isArray(orderBy)) {
      orderBy = orderBy[0];
    }
    let filters = {};
    if (query) {
      rowsPerPage = query.limit;
      page = Math.floor(query.offset / rowsPerPage);
      pages = 0;
      if (dataCount > -1) {
        pages = Math.ceil(dataCount / rowsPerPage);
      }
      if (query.order && query.order.length > 0) {
        if (query.order[0].length > 2) {
          orderBy = query.order[0].slice(0, -1).join('.');
          order = query.order[0][query.order[0].length - 1].toLowerCase();
        } else {
          orderBy = query.order[0][0];
          order = query.order[0][1].toLowerCase();
        }
      }

      // find all where clauses, and condense into one object
      filters = {};
      this.flattenWhereQuery(filters, query, '');
    }

    let title;
    if (header) {
      if (typeof header === 'string') {
        title = (
          <Typography
            component='h2'
            variant='h6'
            color='primary'
            className={classes.title}
          >
            {header}
          </Typography>
        );
      } else {
        title = header;
      }
    }

    let tableClass;
    if (inDialog) {
      tableClass = classes.tableDialog;
    } else {
      tableClass = classes.table;
    }

    let noDataLoaded = null;
    if (dataCount === 0 || !didLoadOccur) {
      // there is no data
      noDataLoaded = (
        <Typography variant='subtitle1' className={classes.noData}>
          {didLoadOccur ? noDataMessage : preloadMessage}
        </Typography>
      );
    }

    // diaolog box for end DOS option
    const dialog = (
      <Dialog open={this.state.open} onClose={this.handleEndDosReject}>
        <DialogTitle> Select an end DOS? </DialogTitle>
        <DialogActions>
          <Button onClick={this.handleEndDosAccept} color='primary'>
            YES
          </Button>
          <Button onClick={this.handleEndDosReject} color='primary'>
            NO
          </Button>
        </DialogActions>
      </Dialog>
    );

    let clearButton = '';

    if (showFilters) {
      clearButton = (
        <Button
          color='black'
          size='small'
          onClick={(e) => this.onClearSearch(query, e)}
        >
          <SearchOffIcon 
            sx={{ fontSize: 20 }} 
          />
        </Button>
      );
    }

    const containerProps = {};
    if (ContainerType === Paper) {
      containerProps.className = classes.root;
    }
    return (
      <ContainerType {...containerProps}>
        {dialog}
        {title}
        <div className={classes.tableWrapper}>
          <Table className={tableClass}>
            <TableHead className={classes.tableHeader}>
              { showFilters && (
                <TableRow  className={classes.groupRow}>
                  <TableCell
                      //key={index}
                      className={classes.settingsRow}
                      padding='none'
                      align='right'
                      colSpan={colcount ? colcount : columns.length}
                    >
                    {clearButton}   
                  </TableCell>
                
                </TableRow>
              )}
              {columnGroups && (
                <TableRow>
                  {columnGroups.map((group, index) => (
                    <TableCell
                      key={index}
                      className={classes.groupCell}
                      padding='normal'
                      colSpan={group.colspan}
                    >
                      {group.label}
                    </TableCell>
                  ))}
                </TableRow>
              )}

              <TableRow>
                {columns.map((column, index) => {
                  let filter;
                  if (showFilters && column.showFilter !== false) {
                    let currentFilter = filters[column.key];
                    if (column.filterFields) {
                      // just use the first one
                      currentFilter = filters[column.filterFields[0]];
                    }

                    let exactFilter = false;

                    if (column.filterExact) {
                      exactFilter = column.filterExact;
                    }

                    if (column.onFilterClick) {
                      filter = (
                        <div className={classes.filterIconButton}>
                          <IconButton
                            onClick={column.onFilterClick}
                            size='large'
                          >
                            <Icon className={classes.filterIcon}>search</Icon>
                          </IconButton>
                        </div>
                      );
                    } else if (column.type === 'date') {
                      // remove the %value% for filter
                      if (
                        currentFilter &&
                        currentFilter.$and &&
                        currentFilter.$and.$gt
                      ) {
                        currentFilter = moment(currentFilter.$and.$gt)
                          .add(1, 'days')
                          .format(DateUtil.FORM_DATE_FORMAT);
                      } else {
                        currentFilter = null;
                      }

                      const className = `${classes.filterInputField} ${classes.filterInputField2} ${classes.dateField}`;

                      filter = (
                        <DatePicker
                          label={this.state.dateRangeLabel}
                          showTodayButton
                          clearable
                          value={currentFilter}
                          onChange={(e) =>
                            this.handleDateOnChange(column.key, e)}
                          onClose={this.handleDateOnClose}
                          onInputChange={(e) =>
                            this.handleDateInputChange(column.key, e)}
                          className={className}
                          fullWidth
                          format={DateUtil.LDML_DISPLAY_FORMAT}
                          style={column.style}
                          keyboard
                          mask={[
                            /\d/,
                            /\d/,
                            '/',
                            /\d/,
                            /\d/,
                            '/',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          ref={(r) => this.setDatePickerDialogReference(r)}
                          disabled={column.filter === false}
                          shouldDisableDate={this.disableDaysBeforeStartDOS}
                        />
                      );
                    } else {
                      // remove the %value% for filter
                      if (
                        currentFilter &&
                        currentFilter.$like &&
                        currentFilter.$like.length > 0
                      ) {
                        currentFilter = currentFilter.$like.substring(
                          1,
                          currentFilter.$like.length - 1
                        );
                      } else if (
                        currentFilter &&
                        column.fulltext &&
                        column.fulltext === true &&
                        currentFilter.$match &&
                        currentFilter.$match.length > 0
                      ) {
                        currentFilter = currentFilter.$match;
                      } else if (
                        exactFilter === true &&
                        currentFilter &&
                        currentFilter.$eq &&
                        currentFilter.$eq.length > 0
                      ) {
                        currentFilter = currentFilter.$eq;
                      } else if (
                        currentFilter &&
                        currentFilter.$eq &&
                        currentFilter.$eq.length > 0
                      ) {
                        currentFilter = `"${currentFilter.$eq}"`;
                      } else {
                        currentFilter = '';
                      }
                      filter = (
                        <TextField
                          id={column.key}
                          InputProps={{
                            className: clsx(
                              classes.filterInputField,
                              classes.filterInputField2
                            ),
                            endAdornment:
                              column.filter !== undefined &&
                              !column.filter ? null : (
                                <InputAdornment position='end'>
                                  <Icon className={classes.filterIcon}>
                                    search
                                  </Icon>
                                </InputAdornment>
                              ),
                          }}
                          onChange={(e) =>
                            this.onFilterChange(column.key, e, false)}
                          onBlur={(e) =>
                            this.onFilterChange(column.key, e, true)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              this.onFilterChange(column.key, e, true);
                            }
                          }}
                          variant='standard'
                          margin='none'
                          value={currentFilter}
                          type={column.type ? column.type : 'search'}
                          disabled={
                            column.filter !== undefined && !column.filter
                          }
                          fullWidth={!(column.style && column.style.width)}
                          style={column.style}
                          autoComplete='disabled'
                        />
                      );
                    }
                  }

                  // Pad first and last column
                  const cellStyle = { ...column.style };
                  if (index === 0) {
                    cellStyle.paddingLeft = 24;
                  } else if (
                    index === columns.length - 1 &&
                    !this.props.expandableRows &&
                    !this.props.rowSettings
                  ) {
                    cellStyle.paddingRight = 24;
                  }

                  return (
                    <TableCell
                      key={column.key}
                      className={classes.headerCell}
                      padding='normal'
                      sortDirection={orderBy === column.key ? order : false}
                      style={cellStyle}
                    >
                      <TableSortLabel
                        tabIndex={-1}
                        key={column.key}
                        active={orderBy === column.key}
                        direction={order}
                        disabled={column.sortable === false}
                        onClick={(e) => this.onOrderChange(column.key, e)}
                      >
                        {column.label}
                      </TableSortLabel>
                      {filter}
                    </TableCell>
                  );
                })}
                {(this.props.expandableRows || this.props.rowSettings) && (
                  <TableCell
                    key={'row-action'}
                    className={classes.headerCell}
                    padding='normal'
                    style={{ width: 70 }}
                  />
                )}
              </TableRow>
            </TableHead>
            {dataRows && (
              <TableBody className={classes.tableBody}>
                <TableRow>
                  {isDataLoading && (
                    <TableCell className={classes.loadingCell} colSpan={columns.length}>
                      <LinearProgress className={classes.loading} />
                    </TableCell>
                  )}
                  {!isDataLoading && (
                    <TableCell className={classes.topCell} colSpan={columns.length}>
                    </TableCell>
                  )}
                </TableRow>
                {dataRows.map((row) => {
                  const isMultiSelected = this.isRowMultiSelected(row);
                  const id = Array.isArray(idKey) ? idKey.map((k) => row[k]).join('_') : row[idKey];
                  altRowFlag = !altRowFlag;
                  if (row.invalid) {
                    rowClass = classes.invalidRow;
                  } else if (row.aggregate) {
                    rowClass = classes.aggregateRow;
                  } else if (altRowFlag) {
                    rowClass = classes.altRow;
                  } else {
                    rowClass = classes.regRow;
                  }
                  // Separate b/c row can be both alt and selected
                  if (isMultiSelected) {
                    rowClass = classes.multiSelectedRow;
                  }
                  const rowHref = this.props.getRowHref
                    ? this.props.getRowHref(row)
                    : null;

                  return (
                    <Fragment key={id}>
                      <TableRow
                        className={rowClass}
                        key={id}
                        onClick={
                          this.props.onRowClick
                            ? (e) => this.onRowClick(row, e)
                            : onMultiSelect
                            ? () => this.handleMultiSelect(row)
                            : undefined
                        }
                      >
                        {columns.map((column, index) => {
                          // Pad first and last column
                          const cellStyle = {};
                          if (index === 0) {
                            cellStyle.paddingLeft = 24;
                          } else if (
                            index === columns.length - 1 &&
                            !this.props.expandableRows &&
                            !this.props.rowSettings
                          ) {
                            cellStyle.paddingRight = 24;
                          }

                          return (
                            <TableCell
                              className={`${classes.condensedCell} ${
                                row.muted ? classes.mutedCell : ''
                              }`}
                              key={`${id}.${column.key}`}
                              padding='normal'
                              style={cellStyle}
                            >
                              {this.wrapCellContent(
                                rowHref,
                                column.format
                                  ? // custom format function
                                    column.format(
                                      this.resolveColumnPath(column, row),
                                      row,
                                      rowClasses,
                                      column.key
                                    )
                                  : this.cellString(
                                      this.resolveColumnPath(column, row),
                                      column
                                    )
                              )}
                            </TableCell>
                          );
                        })}
                        {this.props.expandableRows && (
                          <TableCell
                            key={`${id}.expand`}
                            className={classes.condensedCell}
                            padding='normal'
                            style={{
                              width: 70,
                            }}
                            onClick={(e) => this.handleExpandClick(row, e)}
                          >
                            {!this.state.expandedRows.filter(
                              (r) => r === row[this.props.idKey]
                            ).length > 0 ? (
                              <Icon fontSize='small'>expand_more</Icon>
                            ) : (
                              <Icon fontSize='small'>expand_less</Icon>
                            )}
                          </TableCell>
                        )}
                        {this.props.rowSettings &&
                        this.props.rowSettings(row) ? (
                          <TableCell
                            onClick={(e) =>
                              this.props.onRowSettingsClick(row, e)}
                          >
                            <Icon fontSize='inherit'>more_vert</Icon>
                          </TableCell>
                        ) : (
                          this.props.rowSettings && <TableCell />
                        )}
                      </TableRow>
                      {this.props.expandableRows &&
                        this.state.expandedRows.filter((r) => r === row[idKey])
                          .length > 0 && (
                          <TableRow
                            className={rowClass}
                            key={row[idKey] + '-expanded'}
                          >
                            {this.props.renderExpandedRow(row, columns)}
                          </TableRow>
                        )}
                      {rowExtraRenderer && (
                        <TableRow
                          className={rowClass}
                          key={`extra_${row[idKey]}`}
                          onClick={this.onRowClick(row)}
                        >
                          {rowExtraRenderer(row, columns, altRowFlag)}
                        </TableRow>
                      )}
                    </Fragment>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </div>

        {isDataLoading && !didLoadOccur ? (
          <div className={classes.spinnerWrapper}>
            <CircularProgress color='secondary' />
          </div>
        ) : (
          noDataLoaded
        )}

        {pages > 0 && (
          <TablePagination
            component='div'
            count={dataCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            labelDisplayedRows={({ from, to, count }) => {
              if (count === -1) {
                return `${from.toLocaleString()}-${to.toLocaleString()} more than ${to.toLocaleString()}`;
              }
              return `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`;
            }}
            onPageChange={this.onPageChange}
            onRowsPerPageChange={this.onRowsPerPageChange}
            rowsPerPageOptions={this.props.rowsPerPageOptions ?? [10, 25, 50, 100, 200]}
            classes={{ displayedRows: classes.displayedRows }}
          />
        )}
      </ContainerType>
    );
  }

  wrapCellContent = (href, content) => {
    if (href) {
      return (
        <Link to={href} className={this.props.classes.link}>
          {content}
        </Link>
      );
    } else {
      return content;
    }
  };
}

DynamicTable.defaultProps = {
  idKey: 'id',
  showFilters: true,
  noDataMessage: 'No records found.',
  preloadMessage: '',
  inDialog: false,
  ContainerType: Paper,
};

DynamicTable.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  rowClasses: PropTypes.object,
  columns: PropTypes.array.isRequired,
  colcount: PropTypes.number,
  query: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  idKey: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  showFilters: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  noDataMessage: PropTypes.string,
  preloadMessage: PropTypes.string,
  onQueryChange: PropTypes.func,
  onRowClick: PropTypes.func,
  getRowHref: PropTypes.func,
  expandableRows: PropTypes.bool,
  inDialog: PropTypes.bool,
  rowExtraRenderer: PropTypes.func,
  rowsPerPageOptions: PropTypes.array,
  renderExpandedRow: PropTypes.func,
  onRowSettingsClick: PropTypes.func,
  onMultiSelect: PropTypes.func,
  rowSettings: PropTypes.func,
  multiSelectedRows: PropTypes.array,
  ContainerType: PropTypes.elementType,
};

const styled = withStyles(styles)(withIsMobile(DynamicTable));
export { styled as DynamicTable };
