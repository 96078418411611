import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Typography } from '@mui/material';

import { StoreUtil } from 'doctivity-shared/utils';

import { TableUtil } from 'utils';

import { TableWithDialog, LoadingView } from 'components';

import { queryTaxonomy, upsertTaxonomy } from 'store/actions/taxonomyActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    fontSize: 24,
    paddingRight: 30,
  },
});

const schema = {
  type: 'object',
  required: [],
  properties: {
    show: { type: 'boolean', title: 'Show Specialty' },
  },
};

const uiSchema = {};

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Code',
    key: 'code',
  },
  {
    label: 'Grouping',
    key: 'grouping',
  },
  {
    label: 'Classification',
    key: 'classification',
  },
  {
    label: 'Specialization',
    key: 'specialization',
  },
  {
    label: 'Show',
    key: 'show',
    format: TableUtil.boolToCheckIcon,
    style: { width: 75 },
  },
];

class TaxonomyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        where: {},
        order: [['code', 'ASC']],
        limit: 25,
        offset: 0,
      },
    };

    this.onSave = this.onSave.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
  }

  componentDidMount() {
    const { taxonomy } = this.props;

    if (StoreUtil.needsLoadNoCache(taxonomy)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    if (this.props.app.selectedClient !== prevProps.app.selectedClient) {
      this.fetchData();
    } else if (this.props.editing !== prevProps.editing) {
      // if so and it is newr than the list, update list
      if (
        StoreUtil.hasSavedSinceLoad(this.props.editing, this.props.taxonomy)
      ) {
        this.fetchData();
      }
    }
  }

  onSave(data) {
    const { dispatch } = this.props;

    dispatch(upsertTaxonomy(data));
  }

  onQueryChange(query) {
    this.setState({ query }, this.fetchData.bind(this));
  }

  fetchData() {
    const { dispatch } = this.props;

    const { query } = this.state;

    dispatch(
      queryTaxonomy({
        ...query,
      })
    );
  }

  render() {
    const { taxonomy, editing, classes } = this.props;

    const { query } = this.state;

    // is a user being edited now?
    if (StoreUtil.isSaving(editing)) {
      return <LoadingView />;
    }

    return (
      <>
        <Helmet defer={false}>
          <title>Specialties</title>
        </Helmet>
        <div className={classes.header}>
          <Typography className={classes.headerTitle}>Specialties</Typography>
        </div>
        <div className={classes.container}>
          <TableWithDialog
            title='Specialties'
            dialogTitle='Specialty Settings'
            allowNew={false}
            columns={columns}
            dialogSchema={schema}
            dialogUISchema={uiSchema}
            query={query}
            data={taxonomy}
            onQueryChange={this.onQueryChange}
            onSave={this.onSave}
          />
        </div>
      </>
    );
  }
}

TaxonomyPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object,
  taxonomy: PropTypes.object.isRequired,
  editing: PropTypes.object,
  app: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    taxonomy,
    app,
  } = state;

  return {
    app,
    taxonomy: StoreUtil.get(taxonomy, StoreUtil.COMMON_TABLE),
    editing: StoreUtil.get(taxonomy, StoreUtil.COMMON_EDIT_ITEM),
  };
}

const styled = withStyles(styles)(TaxonomyPage);
const connected = connect(mapStateToProps)(styled);
export { connected as TaxonomyPage };
