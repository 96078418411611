import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import { Typography, Card, Icon, CardContent, TableCell } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';

import { StoreUtil, DateUtil } from 'doctivity-shared/utils';

import { DynamicTable } from 'components';

import { queryActivityLogs } from 'store/actions/activityLogsActions';
import { convertToCSV } from 'documents/downloadTypes';
import fileDownload from 'js-file-download';

import { withRouter } from 'utils';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  condensedCell: {
    padding: 2,
    fontSize: 12,
    fontWeight: 'inherit',
  },
  emptyExpandedRowData: {
    fontSize: 12,
    fontWeight: 'inherit',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
  },
  downloadButton: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  exportIcon: {
    fontSize: 16,
    padding: theme.spacing(1),
  },
  objectDisplay: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '50rem',
  },
});

function diff(obj1, obj2) {
  const result = {};
  if (Object.is(obj1, obj2)) {
    return undefined;
  }
  if (!obj2 || typeof obj2 !== 'object') {
    return obj1;
  }
  if (!obj1 || typeof obj1 !== 'object') {
    return obj2;
  }
  Object.keys(obj1 || {})
    .concat(Object.keys(obj2 || {}))
    .forEach((key) => {
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = obj2[key];
      }
      if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
        const value = diff(obj1[key], obj2[key]);
        if (value !== undefined) {
          result[key] = value;
        }
      }
    });
  return result;
}
const tabs = (depth) => Array(depth).fill('\t').join('');
const prettyPrint = (obj, depth = 0) => {
  if (typeof obj === 'boolean') return obj ? 'True' : 'False';
  if (!obj) return '';
  if (typeof obj === 'string') return obj;
  if (typeof obj === 'number') return obj;
  if (Array.isArray(obj)) {
    if (obj.length === 0) {
      return 'No Entries';
    }
    return obj.map(
      (value) => `${tabs(depth)}${prettyPrint(value, depth)}\n`,
    );
  }
  return Object.entries(obj)
    .map(([key, value]) => {
      if (!value) return null;
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return `${tabs(depth)}${format_snake_case(key)}: No Entries\n`;
        }
        return `${tabs(depth)}${format_snake_case(key)}:\n${prettyPrint(value, depth + 1)}\n`;
      } else if (typeof value === 'object') {
        return `${tabs(depth)}${format_snake_case(key)}:\n${prettyPrint(value, depth + 1)}\n`;
      }
      return `${tabs(depth)}${format_snake_case(key)}: ${prettyPrint(value ?? 'Empty', depth + 1)}\n`;
    })
    .filter((v) => !!v).join('');
};
const format_snake_case = (str) => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
const MODEL_FORMATS = {
  Providers: (context) => {
    return diff(context.previousData, context.data);
  },
  Organizations: (context) => {
    return diff(context.previousData, context.data);
  },
  Locations: (context) => {
    return diff(context.previousData, context.data);
  },
  Contacts: (context) => {
    return diff(context.previousData, context.data);
  },
  Favorites: (context) => {
    return diff(context.previousData, context.data);
  },
  Markets: (context) => {
    return diff(context.previousData, context.data);
  },
  TagNamespaces: (context) => {
    return diff(context.previousData, context.data);
  },
  Tags: (context) => {
    return diff(context.previousData, context.data);
  },
  Documents: (context) => {
    return context.description;
  },
  ProviderComments: (context) => {
    return diff(context.previousData, context.data);
  },
  Clients: (context) => {
    return /Bearer/.test(context.data) ? '' : context.data;
  },
};

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Type',
    key: 'type',
    style: { width: 150 },
  },
  {
    label: 'Description',
    key: 'description',
    format: (description, row) => {
      if (description) {
        return description;
      }
      if (row.type === 'VIEWED') {
        return `${row.model} id: ${row.object_id}`;
      }
      return '';
    },
  },
  {
    label: 'Date & Time',
    key: 'created_at',
    format: DateUtil.formatDateTimeFromDB,
    style: { width: 150 },
  },
  {
    label: 'Client',
    key: 'selected_client.name',
    style: { width: 125 },
  },
  {
    label: 'User',
    key: 'user_id',
    format: (userId, row) => {
      if (row.user) {
        return `${row.user.first_name} ${row.user.last_name}`;
      }
      return '';
    },
    style: { width: 150 },
  },
];
class ActivityLogPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        include: [
          {
            association: 'user',
          },
          {
            association: 'selected_client',
            attributes: ['id', 'name'],
          },
        ],
        order: [['created_at', 'DESC']],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    const { activityLogs } = this.props;

    if (StoreUtil.needsLoadNoCache(activityLogs)) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch } = this.props;

    const { query } = this.state;

    console.log(query);
    dispatch(queryActivityLogs(query));
  }

  render() {
    const { activityLogs, classes } = this.props;

    const { query } = this.state;

    return (
      <>
        <Helmet defer={false}>
          <title>Activity Log</title>
        </Helmet>
        <div className={classes.container}>
          <Card>
            <CardContent>
              <DynamicTable
                header={
                  <div className={classes.tableHeader}>
                    <Typography
                      component='h2'
                      variant='h6'
                      color='primary'
                      gutterBottom
                    >
                      Activity Log
                    </Typography>
                    <div className={classes.downloadButton}>
                      <Icon
                        onClick={() => {
                          this.fetchCSVDownload();
                        }}
                        className={classes.exportIcon}
                      >
                        get_app
                      </Icon>
                    </div>
                  </div>
                }
                columns={columns}
                colcount={columns.length+1}
                data={activityLogs}
                ContainerType={React.Fragment}
                query={query}
                onQueryChange={this.onQueryChange}
                onRowClick={this.onRowClick}
                expandableRows={true}
                rowsPerPageOptions={[25, 50, 2000]}
                renderExpandedRow={(row, columns) => {
                  return columns.map((column) => {
                    if (column.key === 'description') {
                      if (row.context) {
                        let context = JSON.parse(row.context);
                        const fmt = MODEL_FORMATS[row.model];
                        if (fmt) {
                          context = fmt(context);
                        }
                        if (context?.data) {
                          if (context.model === 'Users') {
                            context.data = {
                              email: context.data.email,
                              clients: context.data.client_ids,
                            };
                          } else {
                            delete context.data;
                          }
                        }

                        return (
                          <TableCell
                            key={`${row.id}.${column.key}-expanded`}
                            className={classes.condensedCell}
                            padding='normal'
                            style={column.style}
                          >
                            <pre className={classes.objectDisplay}>
                              {prettyPrint(context)}
                            </pre>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            key={`${row.id}.${column.key}-expanded`}
                            className={classes.condensedCell}
                            padding='normal'
                          >
                            <Typography
                              className={classes.emptyExpandedRowData}
                            >
                              No extra data to show
                            </Typography>
                          </TableCell>
                        );
                      }
                    }
                    return (
                      <TableCell
                        key={`${row.id}.${column.key}-expanded`}
                        className={classes.condensedCell}
                        padding='normal'
                      ></TableCell>
                    );
                  });
                }}
              />
            </CardContent>
          </Card>
        </div>
      </>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };

  onRowClick = (row) => {
    console.log(row);
    if (
      row.type === 'VIEWED' ||
      (row.type === 'DATA_UPSERT' && row.object_id)
    ) {
      if (row.model === 'Providers') {
        this.props.router.navigate(`/providers/${row.object_id}`);
      } else if (row.model === 'Organizations') {
        this.props.router.navigate(`/organizations/${row.object_id}`);
      } else if (row.model === 'Contacts') {
        this.props.router.navigate(`/contacts/${row.object_id}`);
      } else if (row.model === 'Locations') {
        this.props.router.navigate(`/locations/${row.object_id}`);
      }
    }
  };

  fetchCSVDownload() {
    const mapping = {
      displayName: 'Activity Logs',
      helpText: 'Download a .csv of the Activity Logs',
      downloadName: 'doctivity-ACTIVITY-{date}.csv',
      initialQuery: {},
      columnMapping: {
        type: {
          downloadName: 'Type',
        },
        description: {
          downloadName: 'Description',
        },
        created_at: {
          downloadName: 'Date & Time',
          format: DateUtil.formatDateTimeFromDB,
        },
        'selected_client.name': {
          downloadName: 'Selected Client',
        },
        user_id: {
          downloadName: 'User',
          format: (userId, row) => {
            if (row.user) {
              return `${row.user.first_name} ${row.user.last_name}`;
            }
            return '';
          },
        },
        context: {
          downloadName: 'Context',
          format: (val) => {
            if (/Bearer/.test(val)) {
              return 'Bearer: REDACTED';
            }
            return val?.replaceAll('\\\\', '').replaceAll('"', '');
          },
        },
        object_id: {
          downloadName: 'Modified Object ID',
        },
        model: {
          downloadName: 'Modified Object Type',
        },
      },
    };
    const csv = convertToCSV(mapping, this.props.activityLogs?.data?.rows);
    const downloadName = mapping.downloadName.replace(
      '{date}',
      moment().format('YY_MM_DD'),
    );
    fileDownload(csv, downloadName);
  }
}

ActivityLogPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  activityLogs: PropTypes.object.isRequired,
  clients: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { activityLogs, clients } = state;

  return {
    activityLogs: StoreUtil.get(activityLogs, StoreUtil.COMMON_TABLE),
    clients: StoreUtil.get(clients, StoreUtil.COMMON_LIST),
  };
}

const styled = withStyles(styles)(ActivityLogPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ActivityLogPage };
