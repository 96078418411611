export const API_TABLE_USERS = 'API_TABLE_USERS';
export const API_LIST_USERS = 'API_LIST_USERS';
export const API_UPSERT_USER = 'API_UPSERT_USER';
export const API_LOAD_USER = 'API_LOAD_USER';

export const queryUsers = (options, type = API_TABLE_USERS) => {
  return {
    type,
    request: {
      url: '/Users',
      method: 'GET',
      withCredentials: true,
      params: { opts: options },
    },
  };
};

export const listUsers = (clientId) => {
  const query = {
    order: [
      ['last_name', 'ASC'],
    ],
  };

  if (clientId) {
    query.include = [{
      association: 'clients',
      attributes: ['id'],
      where: {
        id: clientId,
      },
      required: true,
    }];
  }
  return {
    type: API_LIST_USERS,
    clientId,
    request: {
      url: '/Users',
      method: 'GET',
      withCredentials: true,
      params: { opts: query },
    },
  };
};

export const listActiveUsers = (clientId) => {
  const query = {
    order: [
      ['last_name', 'ASC'],
    ],
    where: 
    { is_active: true}

    
  };

  if (clientId) {
    query.include = [{
      association: 'clients',
      attributes: ['id'],
      where: {
        id: clientId,
      },
      required: true,
    }];

  }

  return {
    type: API_LIST_USERS,
    clientId,
    request: {
      url: '/Users',
      method: 'GET',
      withCredentials: true,
      params: { opts: query },
    },
  };
};

export const loadUser = (id) => {
  return {
    type: API_LOAD_USER,
    id,
    request: {
      url: `/Users/${id}`,
      method: 'GET',
      params: {
        opts: {
          include: [
            {
              association: 'clients',
              attributes: ['id', 'name'],
            },
          ],
        },
      },
    },
  };
};

export const upsertUser = (data, type = API_UPSERT_USER) => {
  let options = null;
  let id = 0;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      where: { id },
    };
  }

  if (data.clients && Array.isArray(data.clients)) {
    if (options == null) {
      options = {};
    }
    options.include = [
      {
        association: 'clients',
        attributes: ['id'],
      },
    ];
  }

  return {
    type,
    id,
    request: {
      url: '/Users',
      method: 'PUT',
      params: { opts: options },
      data,
    },
  };
};
