import React from 'react';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import dotProp from 'dot-prop-immutable';
import {
  Button,
  Typography,
  TextField,  
  Dialog,
  DialogContent,
  DialogActions,

} from '@mui/material';

import { StoreUtil } from 'doctivity-shared/utils';

import { DynamicTable} from 'components';

import { queryTaxonomy } from 'store/actions/taxonomyActions';

const schema = {
  type: 'object',
  required: [],
};


const uiSchema = {};

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Code',
    key: 'code',
  },
  {
    label: 'Grouping',
    key: 'grouping',
  },
  {
    label: 'Classification',
    key: 'classification',
  },
  {
    label: 'Specialization',
    key: 'specialization',
  }

];


const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  formwrapper: {
    margin: '15px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '45%',
    flex: '1',
    margin: '10px'
  }, 
  columnright: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '45%',
    flex: '1',
    margin: '10px',
    textAlign: 'right',
    color: 'lightgray'


  }, 
  column80: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '50%',
    flex: '1',
    margin: '10px'
  }, 

  column10: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '10%',
    justifyContent: 'center',
    flex: '1',
    margin: '10px'
  }, 
  editButton: {
    color: theme.palette.secondary.main,
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 500,
    fontSize: 'clamp(16px, 2.5vw, 24px)',
    paddingRight: 30,
  },
  headerCode: {
    fontSize: 18,
    fontWeight: 500

  },
  searchField: {
    marginTop: '20px',
    marginBottom: '0px'
  },

});

class SpecialtySearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        where: {},
        order: [['code', 'ASC']],
        limit: 25,
        offset: 0,
      },
    };
  };

  componentDidMount() {
    const { taxonomy } = this.props;

    if (StoreUtil.needsLoadNoCache(taxonomy)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    console.log(prevProps);
    // is object getting edited?
    if (this.props.app.selectedClient !== prevProps.app.selectedClient) {
      this.fetchData();
    }
  }
 
  fetchData() {
    const { dispatch } = this.props;
    const { query } = this.state;

    let qry = queryTaxonomy({
      ...query,
    });

    dispatch(
      //queryTaxonomy({
        //...query,
      //})
      qry
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData);
  };


  onSearchChange(e) {

    const { debounceTimer } = this.state;
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    debounceTimer: setTimeout(() => {
      
      let { query } = this.state;

      //console.log(e.target.value);
      //console.log(query);

      let filters = {};
      let filters2 = {};
      filters2['code'] = {$like: '%' + e.target.value + '%'};
      filters2['grouping'] = {$like: '%' + e.target.value + '%'};
      filters2['classification'] = {$like: '%' + e.target.value + '%'};
      filters2['specialization'] = {$like: '%' + e.target.value + '%'};

      filters['$or'] =  filters2;

      query = dotProp.set(query, 'where', filters);

      this.setState({ query });
      this.setState({ query }, this.fetchData.bind(this));
    }, 500)
  }

  render() {
    const { taxonomy, classes, code} = this.props;

    const { query } = this.state;


    return (
      <Dialog
        open={true}
        onClose={this.props.onClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        
        maxWidth='lg'
       // TransitionProps={{
         // onExited: this.handleExited,
        //}}
      >
        <DialogContent
          style={{height:'90vh'}}> 
          
          
          <div className={classes.formwrapper}>
            <div className={classes.row}>
              <div className={classes.column}>
                <Typography className={classes.headerTitle}>
                    Select Specialties
                </Typography>
              </div>
              <div className={classes.columnright}>
                <Typography className={classes.headerCode}>
                    {code}
                </Typography>
              </div>
            </div>
            <TextField fullWidth id='outlined-search' 
              className={classes.searchField} placeholder='Search for code, grouping, classification, or specialization' type='search' 
              onChange = {(e) => this.onSearchChange(e)}
            />
          </div>

          <div className={classes.container}>
            <DynamicTable
              title='Specialties'
              dialogTitle='Specialties'
              allowNew={false}
              columns={columns}
              dialogSchema={schema}
              dialogUISchema={uiSchema}
              query={query}
              data={taxonomy}
              onQueryChange={this.onQueryChange}
              onRowClick={(row) => this.props.onRowClick(row)}
              showFilters={false}
            />
          </div>
        </DialogContent>
        <DialogActions>
         
              <>
                <Button onClick={this.props.onClose} color='primary'>
                  Cancel
                </Button>
              </>
        </DialogActions>
      </Dialog>
    );
  }
}


SpecialtySearch.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  app: PropTypes.object,
  taxonomy: PropTypes.object.isRequired,
  isPicking: PropTypes.bool,
  code: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const {
    taxonomy,
    app,
  } = state;

  return {
    app,
    taxonomy: StoreUtil.get(taxonomy, StoreUtil.COMMON_TABLE)
  };
}

const styled = withStyles(styles)(SpecialtySearch);
const connected = connect(mapStateToProps)(styled);
export { connected as SpecialtySearch };

